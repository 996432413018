/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "./src/styles/global.css"

import React from "react"
import ToggleAnalytics from "./src/utils/analytics"

export const onRouteUpdate = () => ToggleAnalytics()

export const shouldUpdateScroll = () => {
  window.scrollTo(0, 0)
  return false
}

export const onInitialClientRender = () => {
  const loader = document.getElementById("___loader")
  const gatsby = document.getElementById("___gatsby")
  const body = document.getElementById("body")

  setTimeout(() => {
    if (gatsby && loader) {
      gatsby.style.display = "block"
      loader.style.opacity = 0
    }

    setTimeout(() => {
      if (body && gatsby && loader) {
        body.style.overflow = "auto"
        gatsby.style.opacity = 1
        loader.style.display = "none"
      }
    }, 1000)
  }, 1000)
}
