exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-editor-jsx": () => import("./../../../src/pages/editor.jsx" /* webpackChunkName: "component---src-pages-editor-jsx" */),
  "component---src-pages-index-es-jsx": () => import("./../../../src/pages/index.es.jsx" /* webpackChunkName: "component---src-pages-index-es-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-index-pt-br-jsx": () => import("./../../../src/pages/index.pt-br.jsx" /* webpackChunkName: "component---src-pages-index-pt-br-jsx" */),
  "component---src-templates-games-game-jsx": () => import("./../../../src/templates/games/game.jsx" /* webpackChunkName: "component---src-templates-games-game-jsx" */),
  "component---src-templates-legal-legal-jsx": () => import("./../../../src/templates/legal/legal.jsx" /* webpackChunkName: "component---src-templates-legal-legal-jsx" */),
  "component---src-templates-news-news-post-jsx": () => import("./../../../src/templates/news/newsPost.jsx" /* webpackChunkName: "component---src-templates-news-news-post-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */)
}

