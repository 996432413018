export default function ToggleAnalytics() {
  if (document?.cookie?.includes("ot-ga=true")) {
    const currentScripts = [...document.querySelectorAll("script")]

    if (
      !currentScripts.some(
        script =>
          script.src ===
          `https://www.googletagmanager.com/gtag/js?id=G-02HWLER09V`
      )
    ) {
      let scriptAsync = document.createElement("script")
      scriptAsync.src = `https://www.googletagmanager.com/gtag/js?id=G-02HWLER09V`
      scriptAsync.async = true
      document.body.appendChild(scriptAsync)
    }

    if (
      !currentScripts.some(script => script.innerHTML.includes("G-02HWLER09V"))
    ) {
      let scriptInner = document.createElement("script")
      scriptInner.innerHTML = `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('confg', 'G-02HWLER09V');
                `
      document?.body?.appendChild(scriptInner)
    }
  } else {
    const docScripts = [...document?.querySelectorAll("script")]

    const asyncScript = docScripts?.find(script => {
      return (
        script.src ===
        `https://www.googletagmanager.com/gtag/js?id=G-02HWLER09V`
      )
    })
    if (asyncScript) {
      asyncScript.parentNode.removeChild(asyncScript)
    }

    const innerScript = docScripts?.find(script => {
      return script.innerHTML.includes("G-02HWLER09V")
    })

    if (innerScript) {
      innerScript.parentNode.removeChild(innerScript)
    }
  }
}
